import styled from 'styled-components';
import defaultImg from '../../images/room-1.jpeg';

const StyledLanding = styled.header`
  min-height: 90vh;
  background: url(${props => (props.img ? props.img : defaultImg)}) center/cover
    no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export default StyledLanding;
